/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        svg4everybody();

        $(".nav-primary .nav > .menu-item > a").on("click", function (e) {
          e.preventDefault();
          if( !$(this).parent("li").hasClass("active") ){
            $(".menu-back").toggleClass( "hidden" );
            $(this).parent("li").siblings("li").removeClass("active");
            $(this).parent("li").toggleClass( "active" );
          }
        });

        $(".menu-back").on( "click", function() {
          $(".nav-primary .nav > .menu-item").removeClass("active");
          $(".menu-back").toggleClass( "hidden" );
        });


        function updateSideMatter() {
          var SideMatterReference = $('.side-matter-ref'),
          SideMatterNote = $('.side-matter-note');

          SideMatterReference.each(function(){
            $this = $(this);
          });
        }

        // Initialize the media query
        var mq_sidematter = window.matchMedia('(min-width: 62.5em)');

        // Function to do something with the media query
        function mediaQuery_sidematter(mq_sidematter) {    
          if (mq_sidematter.matches) {
            if ( !$( '.no-mobile-side-matter' ).length ) {
              $('.main').prepend( '<section class="no-mobile content-block no-mobile-side-matter"><aside class="side-matter-container" role="complementary"></aside></section>');
              $('.side-matter-list').appendTo('.no-mobile .side-matter-container');
            }
          }
          else{
            if ( $( '.no-mobile-side-matter' ).length ) {
              $('.side-matter-list').appendTo('.mobile .side-matter-container');
              $( '.no-mobile-side-matter' ).remove();
            }
          }
        }

        // On load
        mediaQuery_sidematter(mq_sidematter);

        // Add a listen event
        mq_sidematter.addListener(mediaQuery_sidematter);

        
        var change_trigger = function(elem){
          // check if clicked item is search or menu trigger
          if( elem.hasClass("search-trigger") ){
            //if trigger is active
            if( elem.hasClass("active")){
              $('.search-trigger use').attr('xlink:href',"#close"); 
            }
            else{
              $('.search-trigger use').attr('xlink:href',"#search");              
            }
          }
          if( elem.hasClass("menu-trigger") ){
            if( elem.hasClass("active")){
              elem.text(roots_menu.close);
            }
            else{
              elem.text(roots_menu.default);
            }
          }
        };

        $(".search-trigger, .menu-trigger").on( "click", function(event) {
          var target, adjust_z, target_trigger, adjust_z_trigger;

          // toggle "active" on trigger
          $(this).toggleClass("active");

          // if search trigger
          if( $(this).hasClass("search-trigger") ){
            target = $(".search-container");
            adjust_z  = $(".nav-primary");
            target_trigger = $(".search-trigger");
            adjust_z_trigger  = $(".menu-trigger");
          }

          // if menu trigger
          if( $(this).hasClass("menu-trigger") ){
            target = $(".nav-primary");
            adjust_z  = $(".search-container");
            target_trigger = $(".menu-trigger");
            adjust_z_trigger  = $(".search-trigger");
          }

          // if trigger is active
          if($(this).hasClass("active")){

            // change target to active
            target.toggleClass("active");

            // update trigger icon/text
            change_trigger(target_trigger);

            // change other trigger & target
            if(adjust_z.hasClass("active")){
              adjust_z_trigger.removeClass("active");
              adjust_z.removeClass("active");
              change_trigger(adjust_z_trigger);
            }
          }
          // close trigger/target
          else{
            target_trigger.removeClass("active");
            target.removeClass("active");
            change_trigger(target_trigger);
          }
        });

        $('.slides').each(function() {
          var parent = $(this).parent().parent(".slideshow");
          var show = $(this); 
          // this line depends on your markup
          var slideshowTimeout = 0;
          if ( parent.hasClass("featured-content-container") ) {
            slideshowTimeout = 7000;
          }

          show.cycle({ 
            fx:     'fade', 
            timeout: slideshowTimeout,
            slides: '> li.slide',
            next: parent.find('.next-slide'), 
            prev: parent.find('.prev-slide'),
            pager: parent.find('.image-nav'),
            pauseOnHover: parent,
            pagerTemplate: '',
            pagerActiveClass: 'active'
          });

          $(this).on( 'cycle-before', function(event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
             
          });
        });

        $('.side-matter-ref').on('click', function(event){
          event.preventDefault();
          smoothScroll($(this.hash));
        });
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // About Alf Richard.
    page_template_template_cd: {
    init: function() {
        // Initialize the media query
        var mq_cd = window.matchMedia('(min-width: 62.5em)');

        // Function to do something with the media query
        function mediaQuery_cd(mq_cd) {    
          if (mq_cd.matches) {
            var contentSections = $('.cd-section'),
            navCount = 1;

            if ( !$( '#cd-vertical-nav' ).length ) {
              $( '<nav id="cd-vertical-nav" class="cd-vertical-nav"><ul></ul></nav>' ).insertBefore('.main');
              contentSections.each(function(){
                $this = $(this);
                var sectionHeading = $this.find('.section-title').html(); 
                var sectionLC = sectionHeading.replace(/(<([^>]+)>)/ig,"").replace(/\//g, "-").replace(/[_\s]/g, '-').toLowerCase();
                var encodeSectionHeading = encodeURIComponent(sectionLC);
                if(sectionHeading.length > 25){
                  sectionHeadingTrunc = jQuery.trim(sectionHeading).substring(0, 25).split(" ").slice(0, -1).join(" ") + "...";
                }
                else{
                  sectionHeadingTrunc = sectionHeading;
                }
                // $this.attr('id', encodeSectionHeading);

                // $this.attr('id', sectionLC);
                $('#cd-vertical-nav ul').append( '<li class="cd-list-item"><a class="cd-list-item-link" href="#'+$this.attr('id')+'" data-number="' + navCount + '"><span class="cd-dot"></span><span class="cd-label">'+sectionHeadingTrunc+'</span></a></li>' );
                navCount++;
              });
            }

            updateNavigation();

            //smooth scroll to the section
            $('#cd-vertical-nav a').on('click', function(event){
              event.preventDefault();
              smoothScroll($(this.hash));
            });
              //smooth scroll to second section
            $('.cd-scroll-down').on('click', function(event){
              event.preventDefault();
              smoothScroll($(this.hash));
            });

            $(window).on('scroll', function(){
              updateNavigation();
            });
          }
        }

        // On load
        mediaQuery_cd(mq_cd);

        // Add a listen event
        mq_cd.addListener(mediaQuery_cd);

        function updateNavigation() {
          var contentSections = $('.cd-section'),
          navigationItems = $('#cd-vertical-nav a');

          contentSections.each(function(){
            $this = $(this);
            var activeSection = $('#cd-vertical-nav a[href="#'+$this.attr('id')+'"]').data('number') - 1;
            if ( ( $this.offset().top - $(window).height()/2 < $(window).scrollTop() ) && ( $this.offset().top + $this.height() - $(window).height()/2 > $(window).scrollTop() ) ) {
              navigationItems.eq(activeSection).addClass('is-selected');
            }else {
              navigationItems.eq(activeSection).removeClass('is-selected');
            }
          });
        }

        // Remove the # from the hash, as different browsers may or may not include it
      var hash = location.hash;
      if(hash != ''){
       // Clear the hash in the URL
       // location.hash = '';   // delete front "//" if you want to change the address bar
        smoothScroll($(hash));
       }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  function smoothScroll(target) {
    $('body,html').animate( { 'scrollTop': target.offset().top - 50 }, 600 );
  }

})(jQuery); // Fully reference jQuery after this point.
